import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import { useFormik } from "formik";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { UploadMedia, Uploadpdf } from "../../utils/uploadMedia";
import { errorToast, successToast } from "../../helpers";
import {
  useLazyDeleteDocsByIdQuery,
  useLazyGetIdProofByIdQuery,
  usePostaddDocumentsMutation,
  usePutUpdateDocsMutation,
} from "../../services/documentation";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from "@react-google-maps/api";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Certifications = () => {
  const [items, setItems] = useState<any[]>([]);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [editCard, setEditCard] = useState<boolean>(false);
  const [editableCard, setEditableCard] = useState<any>();
  const [AchievementsDetails, setAchievementsDetails] = useState<any[]>([]);
  const [video, setVideo] = useState<string | null>(null);
  const [videoTitle, setVideoTitle] = useState<string>("");
  const [vdoDescription, setVdoDescription] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [items2, setItems2] = useState<any[]>([]);
  const [deleteDocById] = useLazyDeleteDocsByIdQuery();
  const [profile_picture, setProfilePicture] = useState<any>(null);
  const [addDocument] = usePostaddDocumentsMutation();
  const [tutorId, setTutorId] = useState<string | null>(null);
  const location = useLocation();
  const { state } = location;
  const { _id } = useParams();
  const [imageId, setImageId] = useState(null);
  const [getIdProofById] = useLazyGetIdProofByIdQuery();
  const [updateDocsApi] = usePutUpdateDocsMutation();
  const [certificationError, setCertificationError] = useState<boolean>(false);
  const targetRef1: any = useRef(null);
  const targetRef2: any = useRef(null);
  const [autocomplete, setAutocomplete] = useState<any>();
  const validationSchema = Yup.object({
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date().required("End date is required"),
    institutionName: Yup.string().required("Institution name is required"),
    fieldOfStudy: Yup.string().required("Field of study is required"),
  });

  const handleImageClick = (imageUrl: React.SetStateAction<string>) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSelectedImage("");
  };

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        formik1.setFieldValue("institutionName", place?.formatted_address);
        formik1.setFieldValue("latitude", lat || "");
        formik1.setFieldValue("longitude", lng || "");
      }
    }
  };

  const handleClear = () => {
    formik1.setFieldValue("latitude", 0);
    formik1.setFieldValue("longitude", 0);
    formik1.setFieldValue("institutionName", "");
  };


  const handleAddClick = async () => {
    if (!profile_picture || !vdoDescription.trim()) {
      setError(true);
      return;
    }

    const payload = {
      tutorId: _id ? _id : tutorId,
      documents: [
        {
          frontImage: profile_picture || "",
          description: vdoDescription,
          documentName: 1,
          fieldOfStudy: formik1.values.fieldOfStudy,
          documentType: 3,
          startDate: formik1.values.startDate,
          endDate: formik1.values.endDate,
          institutionName: formik1.values.institutionName,
          latitude:formik1.values.latitude,
          longitude:formik1.values.longitude,

        },
      ],
    };
    const payloadForEdit = {
      frontImage: profile_picture || "",
      description: vdoDescription,
      fieldOfStudy: formik1.values.fieldOfStudy,
      documentName: 1,
      documentType: 3,
      startDate: formik1.values.startDate,
      endDate: formik1.values.endDate,
      institutionName: formik1.values.institutionName,
      latitude:formik1.values.latitude,
      longitude:formik1.values.longitude,
    };


    if (formik1?.values?.startDate > formik1?.values?.endDate) {
      errorToast("End Date has to be greater than start Date");
      return;
    }
    console.log("add ki body", payload);
    console.log("edit ki body", payloadForEdit);

    if (editCard) {
      try {
        const encryptedBody = generateEncryptedKeyBody(
          payloadForEdit
        ) as CommonBody;
        const response = await updateDocsApi({
          id: editableCard?._id,
          body: encryptedBody,
        }).unwrap();
        if (response.statusCode === 200) {
          successToast("Experience Docs updated successfully");
          setEditableCard(null);
          setEditCard(false);
          setVdoDescription("");
          formik1.setFieldValue("fieldOfStudy", "");
          formik1.setFieldValue("startDate", "");
          formik1.setFieldValue("endDate", "");
          formik1.setFieldValue("institutionName", "");
          formik1.setFieldValue("latitude",  0);
          formik1.setFieldValue("longitude",0);
          setProfilePicture("");
        }
        if (_id) {
          fetchDataById(_id);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "Something went wrong");
      }
    } else {
      try {
        const encryptedBody = (await generateEncryptedKeyBody(
          payload
        )) as CommonBody;
        const response = await addDocument(encryptedBody).unwrap();
        if (response.statusCode === 200) {
          successToast("Education Background Docs added successfully");

          if (_id) {
            fetchDataById(_id);
          }

          const newItem = {
            _id: items2.length.toString(),
            title: videoTitle,
            link: profile_picture,
            description: vdoDescription,
          };
          setItems([newItem, ...items]);
          setProfilePicture(null);
          setVideoTitle("");
          setVdoDescription("");
          formik1.setFieldValue("fieldOfStudy", "");
          formik1.setFieldValue("institutionName", "");
          formik1?.setFieldValue("startDate", "");
          formik1?.setFieldValue("endDate", "");
          formik1.setFieldValue("latitude",  0);
          formik1.setFieldValue("longitude",0);
          setCertificationError(false);
          setError(false);
        }
      } catch (error: any) {
        errorToast(error?.data?.message);
      }
    }
  };

  const fetchDataById = async (tutorId: string, documentType?: number) => {
    try {
      const response = await getIdProofById({
        userId: tutorId,
        documentType: 3,
      }).unwrap();
      if (response.statusCode === 200) {
        setAchievementsDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);

  const scrollToElement = (target: any) => {
    window.scrollTo({
      top: target,
      behavior: "smooth",
    });
  };


  const handleDeleteClick = async (_id: string) => {
    try {
      const response = await deleteDocById({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        const updatedItems = items.filter((item: any) => item._id !== _id);
        setItems(updatedItems);

        setAchievementsDetails((prevDetails: any) =>
          prevDetails.filter((item: any) => item._id !== _id)
        );

        const deletedItem = AchievementsDetails.find(
          (item: any) => item._id === _id
        );
        if (deletedItem && deletedItem.frontImage) {
          setProfilePicture((prevProfilePicture: any) => {
            if (prevProfilePicture === deletedItem.frontImage) {
              return null;
            }
            return prevProfilePicture;
          });
        }

        successToast("Education Background docs deleted successfully");
      } else {
        errorToast("Failed to delete experience docs. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting achievement:", error);
      errorToast("Failed to delete achievement. Please try again.");
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        if (type === "profile_picture") {
          setProfilePicture(null);
        }
        return;
      }

      let res;
      if (file.type === "application/pdf") {
        res = await Uploadpdf(file);
        if (res?.statusCode === 200) {
          if (type === "profile_picture") {
            setProfilePicture(res?.data?.image); // Assuming pdfUrl is the URL of the uploaded PDF
          }
        } else {
          errorToast(res?.message);
        }
      } else {
        res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (type === "profile_picture") {
            setProfilePicture(res?.data?.image); // Assuming image is the URL of the uploaded image
          }
        } else {
          errorToast(res?.message);
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      errorToast("Error uploading file. Please try again.");
    }
  };

  const formik1 = useFormik({
    initialValues: {
      video: video || "",
      videoTitle: videoTitle || "",
      startDate: "",
      endDate: "",
      fieldOfStudy: "",
      institutionName: "",
      latitude:0,
      longitude:0
    },
    validationSchema: validationSchema,
    onSubmit: handleAddClick,
  });

  useEffect(() => {
    const storedTutorId = localStorage.getItem("tutorId");
    if (storedTutorId) {
      setTutorId(storedTutorId);
    }
  }, []);



  return (
    <div className="main_layout">
      <div className="dashboard">
        {state?.type === "edit" ? (
          <h1 className="mn_hdng">Edit Tutor</h1>
        ) : (
          <h1 className="mn_hdng">Education Background</h1>
        )}

        <Button
          className="btn btn_primary"
          onClick={() => {
            navigate("/manage-tutors");
          }}
        >
          Back
        </Button>
      </div>
      <Card className="cards">
        <CardContent>
          <div className="card_main" ref={targetRef2}>
            {AchievementsDetails &&
              AchievementsDetails.map((item: any) => (
                <div key={item._id} className="card">
                  <div
                    style={{
                      position: "absolute",
                      right: "22px",
                      padding: "5px",
                    }}
                  >
                    <Tooltip title="Edit">
                      <EditIcon
                        sx={{ margin: "5px" }}
                        onClick={() => {
                          setEditCard(true);
                          setEditableCard(item);
                          setVdoDescription(item?.description);
                          formik1.setFieldValue(
                            "startDate",
                            moment(item?.startDate).format("YYYY-MM")
                          );
                          formik1.setFieldValue(
                            "institutionName",
                            item?.institutionName
                          );
                          formik1.setFieldValue(
                            "fieldOfStudy",
                            item?.fieldOfStudy
                          );
                          formik1.setFieldValue(
                            "endDate",
                            moment(item?.endDate).format("YYYY-MM")
                          );
                          setProfilePicture(item?.frontImage);
                          scrollToElement(targetRef1.current.offsetTop - 100);

                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <DeleteIcon
                        sx={{ margin: "5px" }}
                        onClick={() => handleDeleteClick(item._id)}
                      />
                    </Tooltip>
                  </div>
                  {item.frontImage && item?.frontImage?.endsWith(".pdf") ? (
                    <EmbedPDF companyIdentifier="yourcompany">
                      <a href={`${item.frontImage}`}>
                        <CardMedia
                          component="img"
                          image="/static/images/check.png"
                          alt="photo"
                          sx={{ width: 200, height: 200 }}
                        />
                      </a>
                    </EmbedPDF>
                  ) : (
                    <CardMedia
                      component="img"
                      image={item.frontImage}
                      alt="photo"
                      sx={{ width: "100%", height: 250 }}
                      onClick={() => handleImageClick(item.frontImage)}
                    />
                  )}
                  <div className="desc">
                    <h4>{item?.description}</h4>
                    <h4>Field: {item?.fieldOfStudy || "-"}</h4>
                    <h4>Start Date: {moment(item?.startDate).format("MMMM YYYY")}</h4>
                    <h4>End Date: {moment(item?.endDate).format("MMMM YYYY")}</h4>
                    <h4>Institutional Name:{item?.institutionName}</h4>
                  </div>
                </div>
              ))}
          </div>

          <Grid container spacing={2} ref={targetRef1}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography
                className="custom_label"
                variant="h5"
                sx={{ paddingTop: -30, width: "40%" }}
              >
                Education Background
              </Typography>
              <Select
                fullWidth
                value={vdoDescription}
                onChange={(e) => setVdoDescription(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  Select Education Background
                </MenuItem>
                <MenuItem value="bachelors">Bachelor's</MenuItem>
                <MenuItem value="masters">Master's</MenuItem>
                <MenuItem value="phd">PhD</MenuItem>
                <MenuItem value="diploma">Diploma</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              {error && !vdoDescription && (
                <h6 className="err_msg">Education background is required.</h6>
              )}
              <div
                style={{
                  flexDirection: "row",
                  paddingTop: 20,
                  marginRight: 6,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Start Date</Typography>
                  <TextField
                    hiddenLabel
                    name="startDate"
                    type="month"
                    variant="outlined"
                    fullWidth
                    placeholder="Start Date"
                    id="startDate"
                    className="text_field"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.startDate}
                    error={formik1.touched.startDate && Boolean(formik1.errors.startDate)}
                    helperText={formik1.touched.startDate && formik1.errors.startDate}
                  />

                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">End Date</Typography>
                  <TextField
                    hiddenLabel
                    name="endDate"
                    type="month"
                    variant="outlined"
                    fullWidth
                    placeholder="End Date"
                    id="endDate"
                    className="text_field"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.endDate}
                    error={formik1.touched.endDate && Boolean(formik1.errors.endDate)}
                    helperText={formik1.touched.endDate && formik1.errors.endDate}
                  />

                </Grid>
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}></Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography
                className="custom_label"
                variant="h5"
                sx={{ width: "100%" }}
              >
                Institution Name
              </Typography>

              {/* <TextField
                hiddenLabel
                type={"text"}
                name="institutionName"
                variant="outlined"
                fullWidth
                placeholder="Institution Name"
                id="institutionName"
                className="text_field"
                onChange={formik1.handleChange} // Update the state with the input value
                onBlur={formik1.handleBlur}
                error={formik1.touched.institutionName && Boolean(formik1.errors.institutionName)}
                helperText={formik1.touched.institutionName && formik1.errors.institutionName}
                value={formik1?.values?.institutionName}
              /> */}
              <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={() => onPlaceChanged()}

                >
                  <TextField
                    className="text_field"
                    hiddenLabel
                    name="institutionName"
                    value={formik1.values?.institutionName}
                    placeholder="Institution Name"
                    fullWidth
                    helperText={
                      formik1.touched.institutionName &&
                        typeof formik1.errors.institutionName === "string"
                        ? formik1.errors.institutionName
                        : undefined
                    }
                    onChange={(e) => {
                      if (
                        e.target.value.trim() === "" ||
                        e.target.value === "."
                      ) {
                        formik1.setFieldValue("institutionName", "");
                      } else {
                        formik1.handleChange(e);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    InputProps={{
                      endAdornment: formik1.values.institutionName ? (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClear}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                </Autocomplete>

            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography
                className="custom_label"
                variant="h5"
                sx={{ paddingTop: -30, width: "40%" }}
              >
                Field of study
              </Typography>

              <TextField
                hiddenLabel
                type={"text"}
                name="fieldOfStudy"
                variant="outlined"
                fullWidth
                placeholder="Enter field of study"
                className="text_field"
                onChange={formik1.handleChange}
                onBlur={formik1.handleBlur}
                error={formik1.touched.fieldOfStudy && Boolean(formik1.errors.fieldOfStudy)}
                helperText={formik1.touched.fieldOfStudy && formik1.errors.fieldOfStudy}
                value={formik1?.values?.fieldOfStudy}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              {/* <Typography className="custom_label">njbnbn</Typography> */}

              {profile_picture ? (
                profile_picture.endsWith(".pdf") ? (
                  <EmbedPDF companyIdentifier="yourcompany">
                    <a href={`${profile_picture}`}>
                      <div className="upload_image_preview2">
                        <CardMedia
                          component="img"
                          image="/static/images/check.png"
                          alt="photo"
                          sx={{ width: 200, height: 200 }}
                        />
                        <CancelIcon
                          onClick={() => setProfilePicture(null)}
                          color="error"
                        />
                      </div>
                    </a>
                  </EmbedPDF>
                ) : (
                  <div className="upload_image_preview2">
                    <CardMedia
                      component="img"
                      image={profile_picture}
                      alt="photo"
                      sx={{ width: 200, height: 200 }}
                    />
                    <CancelIcon
                      onClick={() => setProfilePicture(null)}
                      color="error"
                    />
                  </div>
                )
              ) : (
                <Box className="upload_image_bnr">
                  <label htmlFor="icon-button-file-vdo">
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file-vdo"
                      type="file"
                      inputProps={{
                        accept: "image/png,image/jpeg,application/pdf",
                      }}
                      onChange={(e: any) =>
                        handleImageUpload(e, "profile_picture")
                      }
                    />
                    <Button component="span" className="upload_image_btn">
                      <AddIcon />
                    </Button>
                  </label>
                </Box>
              )}
              {error && !profile_picture && (
                <h6 className="err_msg">Image is required.</h6>
              )}
            </Grid>
            <div style={{ marginTop: 20 }} />
          </Grid>

          <Grid item lg={8} md={8} sm={8} xs={8} />

          <Grid item lg={8} md={8} sm={8} style={{ paddingTop: "8px" }}>
            {items?.length < 100 ? (
              <div className="form_btn">
                <Button
                  size="large"
                  type="button"
                  className="btn btn_primary"
                  onClick={() => {
                    formik1.handleSubmit();
                    // handleAddClick();
                    scrollToElement(targetRef2.current.offsetTop - 100);
                  }}
                >
                  {editCard ? "Save" : "Add"}
                </Button>
              </div>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={openModal} onClose={closeModal} maxWidth="xl" fullWidth>
        <DialogTitle>Zoomed Image</DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <img
              src={selectedImage}
              alt="Zoomed Image"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Certifications;
