 
// @ts-ignore
import io from 'socket.io-client';
// "undefined" means the URL will be computed from the `window.location` object
// export const SOCKET_URL = `https://tutorapi.appgrowthcompany.com`;
export const SOCKET_URL = "https://api.tutorhail.com";


export const socket = io(SOCKET_URL, {
  autoConnect: false,
});